import * as React from 'react';

const ContainersCTA = () => (
  <article className="w-full max-w-5xl pt-12 mx-auto my-auto">
    <p className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
      Versatile <br />
      shipping containers <br />
      available for <br />
      <span className="text-brand-orange">purchase</span>
    </p>
  </article>
);

export default ContainersCTA;
